<template>
  <div>
    <!-- Styled -->
    <b-card>
      <!-- <b-row class="d-flex flex-column justify-content-center align-items-center">
        <h3>
          {{ $t("Download premade forms for your need from here") }}
        </h3>
        <p>
          {{ $t("You can download the forms from download buttons") }}
        </p>
      </b-row> -->
      <b-row class="d-flex justify-content-center align-items-end">
        <div class="m-5 d-flex flex-column justify-content-center align-items-center w-25">
          <b-img fluid thumbnail :src="classTeacherForm"/>
          <p class="mt-1">{{ $t("Classroom Teacher Evaluation Form") }}</p>
          <div class="mt-3">
            <!-- <b-button variant="primary">{{ $t("Download") }}</b-button> -->
            <b-button @click="downloadPDFTeacher" variant="primary" class="ml-1"><feather-icon icon="PrinterIcon" size="15"></feather-icon></b-button>
            
          </div>
        </div>
        <div class="m-5 d-flex flex-column justify-content-center align-items-center w-25">
          <b-img fluid thumbnail :src="clientForm"/>
          <p class="mt-1">{{ $t("Client Application Form") }}</p>
          <div class="mt-3">
            <!-- <b-button @click="downloadPDF" variant="primary" >{{ $t("Download2") }}</b-button> -->
            <b-button @click="downloadPDF" variant="primary" class="ml-1" ><feather-icon icon="PrinterIcon" size="15"></feather-icon></b-button>
          </div>
        </div>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BFormFile, BCard, BImg, BRow, BButton, BCol } from "bootstrap-vue";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
import axios from "@axios";
export default {
  components: {
    BCol,
    BCard,
    BFormFile,
    BImg,
    BRow,
    BButton,
  },
  data() {
    return {
      classTeacherForm: require("@/assets/images/forms/classroomteacherform2.jpg"),
      clientForm: require("@/assets/images/forms/clientapplicationform.jpg"),
      /* classTeacherFormPdf: require("@/assets/images/forms/classroomteacherform.pdf"),
      clientFormPdf: require("@/assets/images/forms/clientapplicationform.pdf"), */
    };
  },
  methods: {
    downloadPDF() {
      console.log("download pdf");
      /* axios.get().then(
        response => {
        console.log(response); */
        /* const url = window.URL.createObjectURL(); */
        const link = document.createElement("a");
        link.href = axios.defaults.baseURL+"/files/forms-clientapplicationform.pdf";
        /* link.setAttribute("download", "myFile.pdf"); */
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
      /* }
      ); */
    },
    downloadPDFTeacher(){
      const link = document.createElement("a");
        link.href = axios.defaults.baseURL+"/files/forms-classroomteacherform.pdf";
        /* link.setAttribute("download", "myFile.pdf"); */
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
    }
  },
};
</script>
